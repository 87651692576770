import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import AdminDashboardLayout from "./AdminDashboardLayout";
import { Table } from "react-bootstrap";
import { AuthContext } from "../../../app/context";
import { useNavigate } from "react-router-dom";
import PlanModal from "./createPlanModel";

const AdminDashboardUserTable = () => {
  const apiUrl = process.env.REACT_APP_BACKEND_API;
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [selectedUser, setSelectedUser] = useState({});
  const [users, setUsers] = useState([]);
  const [show, setShow] = useState(false);
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const resp = await axios.get(`${apiUrl}/admin-dashboard/get-all-user`, {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        });
        setUsers(resp.data.users);
      } catch (error) {
        if (error.response.data?.message === "Invalid Token") {
          navigate("/signin");
        }
        console.log(error.response.data);
      }
    };
    fetchUsers();
  }, []);
  const handleShowPlan = (id) => {
    navigate(`/dashboard/admin/plan/${id}`);
  };
  const handleCreatePlan = (user) => {
    setSelectedUser(user);
    handleShow();
  };
  const handleClose = () => {
    setSelectedUser({});
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };
  return (
    <AdminDashboardLayout>
      <>
        <h4 className="text-center p-2">User Lists</h4>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>User Name</th>
              <th>Email</th>
              <th>Role</th>
              <th colSpan={2} className="text-center">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {users?.map((user, index) => {
              return (
                <tr key={user._id}>
                  <td>{index + 1}</td>
                  <td>{user.userName}</td>
                  <td>{user.email}</td>
                  <td>{user.role}</td>

                  <td
                    className="pointer text-blue"
                    onClick={() => handleShowPlan(user._id)}
                  >
                    view plans
                  </td>
                  <td
                    className="pointer text-blue"
                    onClick={() => handleCreatePlan(user)}
                  >
                    create plan
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </>
      <PlanModal
        selectedUser={selectedUser}
        show={show}
        handleClose={handleClose}
      />
    </AdminDashboardLayout>
  );
};

export default AdminDashboardUserTable;
