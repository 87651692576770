import axios from "axios";
import { toast } from "react-toastify";
const apiUrl = process.env.REACT_APP_BACKEND_API;
// const apiUrl = "http://localhost:3333";
export const visitedPath = async (planId, newVisitedPath) => {
  if (!planId || !visitedPath) {
    return;
  } else {
    try {
      const resp = await axios.post(`${apiUrl}/plan/addVisitedPath`, {
        planId,
        newVisitedPath,
      });
      console.log(resp);
    } catch (error) {
      toast.error(error.message);
      console.log(error.message);
    }
  }
};
