import React from "react";
import Navbar from "../../shared/navbar";
import Footer from "../../shared/footer";
import { Container, Row, Col, Nav, Table } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
// import { House, FileText, Gear } from "react-bootstrap-icons";
const AdminDashboardLayout = ({ children }) => {
  const location = useLocation();
  const currentPath = location.pathname;
  return (
    <div>
      <Navbar />
      <div className="pt-5">
        <Row>
          {/* Sidebar */}
          <Col md={2} className=" p-3" style={{ backgroundColor: "#146EB6" }}>
            <h4 className="mx-5 p-3 text-white">Dashboard</h4>
            <hr />
            <ul className="flex-column gap-4  mt-2 align-items-center ">
              <NavLink to={"/dashboard/admin"} href="#home">
                <h5
                  className="mx-5"
                  style={{
                    color:
                      currentPath === "/dashboard/admin" ? "white" : "#00A2D6",
                  }}
                >
                  User List
                </h5>
              </NavLink>
              <NavLink to={"/dashboard/admin/settings"}>
                <h5
                  className="mt-3 mx-5"
                  style={{
                    color:
                      currentPath === "/dashboard/admin/settings"
                        ? "white"
                        : "#00A2D6",
                  }}
                >
                  Account Settings
                </h5>
              </NavLink>
            </ul>
          </Col>

          {/* Content Area */}
          <Col md={10} className="p-4">
            {children}
          </Col>
        </Row>
      </div>
      <Footer />
    </div>
  );
};

export default AdminDashboardLayout;
