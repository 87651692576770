import { Icon } from "@iconify/react";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import arrow from "../../../src/assets/images/breadcrumbsArrow.png";
import { visitedPath } from "../shared/visitedPath";
import { PlanContext } from "../../app/context";
const TestGoal = () => {
  const { plan } = useContext(PlanContext);
  const navigate = useNavigate();
  const location = useLocation();
  const apiUrl = process.env.REACT_APP_BACKEND_API;
  // add path to plan visited
  useEffect(() => {
    visitedPath(plan?._id, { title: "Update Goal", path: "/goal" });
  }, [location.pathname]);

  const [formData, setFormData] = useState([
    {
      type: "Impact",
      extraInfo:
        "(What Impact Do You Want Your Business To Have On Your Staff, Customers, Suppliers, Community, Society, The Environment, Etc?)",
      editable: false,
      editedType: "",
      satisfaction: { time1: "3", goal1: "", time2: "1", goal2: "" },
      stretch: { time1: "3", goal1: "", time2: "1", goal2: "" },
    },
    {
      type: "Revenue",
      editable: false,
      extraInfo: "",
      editedType: "",
      satisfaction: { goal1: "", goal2: "", time1: "3", time2: "1" },
      stretch: { goal1: "", goal2: "", time1: "3", time2: "1" },
    },
    {
      type: "Profit",
      editable: false,
      extraInfo: "",
      editedType: "",
      satisfaction: { goal1: "", goal2: "", time1: "3", time2: "1" },
      stretch: { goal1: "", goal2: "", time1: "3", time2: "1" },
    },
    {
      type: "Team Size",
      editable: false,
      extraInfo: "",
      editedType: "",
      satisfaction: { goal1: "", goal2: "", time1: "3", time2: "1" },
      stretch: { goal1: "", goal2: "", time1: "3", time2: "1" },
    },
    {
      type: "Reach",
      editable: false,
      extraInfo: "(Geography Or Market Segments)",
      editedType: "",
      satisfaction: { goal1: "", goal2: "", time1: "3", time2: "1" },
      stretch: { goal1: "", goal2: "", time1: "3", time2: "1" },
    },
    {
      type: "Culture",
      editable: false,
      extraInfo: "(What Kind of Culture Will You Have)",
      editedType: "",
      satisfaction: { goal1: "", goal2: "", time1: "3", time2: "1" },
      stretch: { goal1: "", goal2: "", time1: "3", time2: "1" },
    },
    {
      type: "Customer Satisfaction",
      editable: false,
      extraInfo: "",
      editedType: "",
      satisfaction: { goal1: "", goal2: "", time1: "3", time2: "1" },
      stretch: { goal1: "", goal2: "", time1: "3", time2: "1" },
    },
    {
      type: "Employee Satisfaction",
      editable: false,
      extraInfo: "",
      editedType: "",
      satisfaction: { goal1: "", goal2: "", time1: "3", time2: "1" },
      stretch: { goal1: "", goal2: "", time1: "3", time2: "1" },
    },
  ]);
  const [selectedOptionA, setSelectedOptionA] = useState("");
  const [selectedOptionB, setSelectedOptionB] = useState("");
  const [selectedOptionC, setSelectedOptionC] = useState("");
  const [selectedOptions, setselectedOptions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/goal/${plan._id}`);
        const data = await response.json();
        // console.log(data);
        if (data) {
          setFormData(data[0].goals);
          setSelectedOptionA(data[0].selectedGoals[0]);
          setSelectedOptionB(data[0].selectedGoals[1]);
          setSelectedOptionC(data[0].selectedGoals[2]);
        }
      } catch (error) {}
    };
    fetchData();
  }, []);

  const handleInputChange = (e, index, category, field) => {
    const { name, value } = e.target;
    setFormData((prevState) => {
      const updatedFormData = [...prevState];
      updatedFormData[index][category][field] = value;
      return updatedFormData;
    });
  };

  const handleDoubleClick = (index) => {
    if (index >= 8) {
      setFormData((prevState) => {
        const updatedFormData = [...prevState];
        updatedFormData[index].editable = true;
        return updatedFormData;
      });
    }
  };

  const handleSaveType = (index) => {
    setFormData((prevState) => {
      const updatedFormData = [...prevState];
      updatedFormData[index].type = updatedFormData[index].editedType;
      updatedFormData[index].editable = false;
      return updatedFormData;
    });
  };

  const handleTypeChange = (e, index) => {
    const { value } = e.target;
    setFormData((prevState) => {
      const updatedFormData = [...prevState];
      updatedFormData[index].editedType = value;
      return updatedFormData;
    });
  };
  const handleSelectChangeA = (e) => {
    const selectedValue = e.target.value;
    setSelectedOptionA(selectedValue);
  };

  const handleSelectChangeB = (e) => {
    const selectedValuea = e.target.value;
    setSelectedOptionB(selectedValuea);
  };

  const handleSelectChangeC = (e) => {
    const selectedValueb = e.target.value;
    setSelectedOptionC(selectedValueb);
  };

  let currentLink = [];
  const crumbs = location.pathname
    .split("/")
    .filter((crumb) => crumb !== "")
    .map((crumb) => {
      currentLink.push(crumb);
      return (
        <div className="crumb" key={crumb}>
          <p>{crumb}</p>
        </div>
      );
    });
  const handle = async () => {
    const selectedGoals = [selectedOptionA, selectedOptionB, selectedOptionC];
    console.log("The goals are: ", selectedGoals);
    setselectedOptions(selectedGoals);
    try {
      const response = await fetch(`${apiUrl}/goal`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          goals: formData,
          selectedGoals: selectedGoals,
          plan: plan._id,
        }),
      });

      // Handle response as needed
      const data = await response.json();
      console.log(data);
      navigate("/swot", {
        state: { goals: selectedGoals, SelectOpt: selectedOptions },
      });
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  const addMore = () => {
    setFormData((prevState) => [
      ...prevState,
      {
        type: "Custom Goal",
        editable: false,
        extraInfo: "",
        editedType: "",
        satisfaction: { goal1: "", goal2: "", time1: "3", time2: "1" },
        stretch: { goal1: "", goal2: "", time1: "3", time2: "1" },
      },
    ]);
  };
  const deleteGoal = (index) => {
    setFormData((prevState) => {
      const updatedFormData = [...prevState];
      updatedFormData.splice(index, 1);
      return updatedFormData;
    });
    setSelectedOptionA("");
    setSelectedOptionB("");
    setSelectedOptionC("");
  };
  return (
    <div className="goal">
      <div className="goalContent">
        <div className="titleheading">
          <div
            className="main-breadcrumps"
            style={{
              display: "flex",
              cursor: "pointer",
              alignItems: "center",
            }}
          >
            <p
              className="breadCrumbs"
              style={{ display: "flex" }}
              onClick={() => navigate("/plans")}
            >
              Plans
              <img className="arrowCrumbs" src={arrow} alt="" />
            </p>
            <p
              className="breadCrumbs"
              style={{ display: "flex" }}
              onClick={() => navigate("/questionnaire ")}
            >
              Questionnaire
              <img className="arrowCrumbs" src={arrow} alt="" />
            </p>
            <p className="breadCrumbs">{crumbs}</p>
          </div>
          <h6 className="mt-2 mb-2 text-center ">
            (You are viewing {plan?.planUser}'s Plan)
          </h6>
          <div className="goaltitle text-center">
            <h5>Goals & Performance</h5>
          </div>
        </div>
        <div className="performance-card">
          <div className="performance-content">
            <div className="right-side">
              <div className="right-l">
                <div className="title">
                  <h6>Satisfaction Goal</h6>
                </div>
              </div>
              <div className="right-r">
                <div className="title">
                  <h6>Stretch Goal</h6>
                </div>
              </div>
            </div>
          </div>
          {formData.map((item, index) => (
            <div key={index} className="performance-content">
              <div className="left-side">
                <div className="content-div Profit-clr">
                  {item.editable ? (
                    <div>
                      <input
                        type="text"
                        value={item.editedType}
                        onChange={(e) => handleTypeChange(e, index)}
                      />
                      <button onClick={() => handleSaveType(index)}>
                        Save
                      </button>
                    </div>
                  ) : (
                    <div>
                      <h6 onClick={() => handleDoubleClick(index)}>
                        {item.type}
                      </h6>
                      <p>{item.extraInfo}</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="right-side">
                <div className="right-l">
                  <div className="title"></div>
                  <div className="content">
                    <div className="containers">
                      <select
                        name="time1"
                        value={item?.satisfaction?.time1}
                        onChange={(e) =>
                          handleInputChange(e, index, "satisfaction", "time1")
                        }
                      >
                        <option value="1">1 Years</option>
                        <option value="3">3 Years</option>
                        <option value="2">2 Years</option>
                        <option value="5">5 Years</option>
                      </select>
                      <input
                        type="text"
                        placeholder="Type here"
                        name="goal1"
                        value={item?.satisfaction?.goal1}
                        onChange={(e) =>
                          handleInputChange(e, index, "satisfaction", "goal1")
                        }
                      />
                    </div>
                    <div className="containers">
                      <select
                        name="time2"
                        value={item?.satisfaction?.time2}
                        onChange={(e) =>
                          handleInputChange(e, index, "satisfaction", "time2")
                        }
                      >
                        <option value="1">1 Years</option>
                        <option value="3">3 Years</option>
                        <option value="2">2 Years</option>
                        <option value="5">5 Years</option>
                      </select>
                      <input
                        type="text"
                        placeholder="Type here"
                        name="goal2"
                        value={item?.satisfaction?.goal2}
                        onChange={(e) =>
                          handleInputChange(e, index, "satisfaction", "goal2")
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="right-r">
                  <div className="title"></div>
                  <div className="content">
                    <div className="containers">
                      <select
                        name="time1"
                        value={item?.stretch?.time1}
                        onChange={(e) =>
                          handleInputChange(e, index, "stretch", "time1")
                        }
                      >
                        <option value="1">1 Years</option>
                        <option value="3">3 Years</option>
                        <option value="2">2 Years</option>
                        <option value="5">5 Years</option>
                      </select>
                      <input
                        type="text"
                        placeholder="Type here"
                        name="goal1"
                        value={item?.stretch?.goal1}
                        onChange={(e) =>
                          handleInputChange(e, index, "stretch", "goal1")
                        }
                      />
                    </div>
                    <div className="containers">
                      <select
                        name="time2"
                        value={item?.stretch?.time2}
                        onChange={(e) =>
                          handleInputChange(e, index, "stretch", "time2")
                        }
                      >
                        <option value="1">1 Years</option>
                        <option value="3">3 Years</option>
                        <option value="2">2 Years</option>
                        <option value="5">5 Years</option>
                      </select>
                      <input
                        type="text"
                        placeholder="Type here"
                        name="goal2"
                        value={item?.stretch?.goal2}
                        onChange={(e) =>
                          handleInputChange(e, index, "stretch", "goal2")
                        }
                      />
                    </div>
                  </div>
                  {index >= 8 && (
                    <div className="d-flex justify-content-end">
                      <button
                        className="mt-3 btn btn-danger"
                        onClick={() => deleteGoal(index)}
                      >
                        Remove Goal
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
          <div className="addnew">
            <div className="addSection" onClick={addMore}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M11.2562 12.8V15.2C11.2562 15.4267 11.3328 15.6167 11.4862 15.77C11.6395 15.9233 11.8295 16 12.0562 16C12.2828 16 12.4728 15.9233 12.6262 15.77C12.7795 15.6167 12.8562 15.4267 12.8562 15.2V12.8H15.2562C15.4828 12.8 15.6728 12.7233 15.8262 12.57C15.9795 12.4167 16.0562 12.2267 16.0562 12C16.0562 11.7733 15.9795 11.5833 15.8262 11.43C15.6728 11.2767 15.4828 11.2 15.2562 11.2H12.8562V8.8C12.8562 8.57333 12.7795 8.38333 12.6262 8.23C12.4728 8.07667 12.2828 8 12.0562 8C11.8295 8 11.6395 8.07667 11.4862 8.23C11.3328 8.38333 11.2562 8.57333 11.2562 8.8V11.2H8.85615C8.62949 11.2 8.43949 11.2767 8.28615 11.43C8.13282 11.5833 8.05615 11.7733 8.05615 12C8.05615 12.2267 8.13282 12.4167 8.28615 12.57C8.43949 12.7233 8.62949 12.8 8.85615 12.8H11.2562ZM12.0562 20C10.9495 20 9.90949 19.79 8.93615 19.37C7.96282 18.95 7.11615 18.38 6.39615 17.66C5.67615 16.94 5.10615 16.0933 4.68615 15.12C4.26615 14.1467 4.05615 13.1067 4.05615 12C4.05615 10.8933 4.26615 9.85333 4.68615 8.88C5.10615 7.90667 5.67615 7.06 6.39615 6.34C7.11615 5.62 7.96282 5.05 8.93615 4.63C9.90949 4.21 10.9495 4 12.0562 4C13.1628 4 14.2028 4.21 15.1762 4.63C16.1495 5.05 16.9962 5.62 17.7162 6.34C18.4362 7.06 19.0062 7.90667 19.4262 8.88C19.8462 9.85333 20.0562 10.8933 20.0562 12C20.0562 13.1067 19.8462 14.1467 19.4262 15.12C19.0062 16.0933 18.4362 16.94 17.7162 17.66C16.9962 18.38 16.1495 18.95 15.1762 19.37C14.2028 19.79 13.1628 20 12.0562 20ZM12.0562 18.4C13.8428 18.4 15.3562 17.78 16.5962 16.54C17.8362 15.3 18.4562 13.7867 18.4562 12C18.4562 10.2133 17.8362 8.7 16.5962 7.46C15.3562 6.22 13.8428 5.6 12.0562 5.6C10.2695 5.6 8.75615 6.22 7.51615 7.46C6.27615 8.7 5.65615 10.2133 5.65615 12C5.65615 13.7867 6.27615 15.3 7.51615 16.54C8.75615 17.78 10.2695 18.4 12.0562 18.4Z"
                  fill="#00A2D6"
                />
              </svg>
              <span>Click to add new goal</span>
            </div>
          </div>
        </div>
        <div className="rankgoal">
          <div className="rankgoal-content">
            <div className="rank-title">
              <h6>Rank Top 3 Goals For This Year</h6>
            </div>
            <div className="goals-card">
              <div className="first-goal">
                <div className="container-first">
                  <select
                    name="option1"
                    value={selectedOptionA}
                    onChange={handleSelectChangeA}
                  >
                    <option value="" disabled>
                      Select an option
                    </option>
                    {formData.map((data, index) => (
                      <React.Fragment key={index}>
                        {data?.satisfaction?.goal1 && (
                          <option value={data?.satisfaction?.goal1}>
                            {data?.satisfaction?.goal1}
                          </option>
                        )}
                        {data?.satisfaction?.goal2 && (
                          <option value={data?.satisfaction?.goal2}>
                            {data?.satisfaction?.goal2}
                          </option>
                        )}
                        {data?.stretch?.goal1 && (
                          <option value={data?.stretch?.goal1}>
                            {data?.stretch?.goal1}
                          </option>
                        )}
                        {data?.stretch?.goal2 && (
                          <option value={data?.stretch?.goal2}>
                            {data?.stretch?.goal2}
                          </option>
                        )}
                      </React.Fragment>
                    ))}
                  </select>

                  <div className="selected-option" id="selectedOption">
                    {selectedOptionA ? selectedOptionA : " Select First Goal"}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      className="svg"
                    >
                      <path
                        d="M8.49999 10.4663C8.4111 10.4663 8.32777 10.4524 8.24999 10.4246C8.17221 10.3968 8.09999 10.3496 8.03333 10.2829L4.96666 7.21628C4.84444 7.09405 4.78333 6.9385 4.78333 6.74961C4.78333 6.56072 4.84444 6.40516 4.96666 6.28294C5.08888 6.16072 5.24444 6.09961 5.43333 6.09961C5.62221 6.09961 5.77777 6.16072 5.89999 6.28294L8.49999 8.88294L11.1 6.28294C11.2222 6.16072 11.3778 6.09961 11.5667 6.09961C11.7555 6.09961 11.9111 6.16072 12.0333 6.28294C12.1555 6.40516 12.2167 6.56072 12.2167 6.74961C12.2167 6.9385 12.1555 7.09405 12.0333 7.21628L8.96666 10.2829C8.89999 10.3496 8.82777 10.3968 8.74999 10.4246C8.67221 10.4524 8.58888 10.4663 8.49999 10.4663Z"
                        fill="#0D4D80"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              <div className="first-goal">
                <div className="container-first">
                  <select
                    name="option2"
                    value={selectedOptionB}
                    onChange={handleSelectChangeB}
                  >
                    <option value="" disabled>
                      Select an option
                    </option>
                    {formData.map((data, index) => (
                      <React.Fragment key={index}>
                        {data?.satisfaction?.goal1 && (
                          <option value={data?.satisfaction?.goal1}>
                            {data?.satisfaction?.goal1}
                          </option>
                        )}
                        {data?.satisfaction?.goal2 && (
                          <option value={data?.satisfaction?.goal2}>
                            {data?.satisfaction?.goal2}
                          </option>
                        )}
                        {data?.stretch?.goal1 && (
                          <option value={data?.stretch?.goal1}>
                            {data?.stretch?.goal1}
                          </option>
                        )}
                        {data?.stretch?.goal2 && (
                          <option value={data?.stretch?.goal2}>
                            {data?.stretch?.goal2}
                          </option>
                        )}
                      </React.Fragment>
                    ))}
                  </select>

                  <div className="selected-option" id="selectedOptiona">
                    {selectedOptionB ? selectedOptionB : " Select Second Goal"}{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      className="svg"
                    >
                      <path
                        d="M8.49999 10.4663C8.4111 10.4663 8.32777 10.4524 8.24999 10.4246C8.17221 10.3968 8.09999 10.3496 8.03333 10.2829L4.96666 7.21628C4.84444 7.09405 4.78333 6.9385 4.78333 6.74961C4.78333 6.56072 4.84444 6.40516 4.96666 6.28294C5.08888 6.16072 5.24444 6.09961 5.43333 6.09961C5.62221 6.09961 5.77777 6.16072 5.89999 6.28294L8.49999 8.88294L11.1 6.28294C11.2222 6.16072 11.3778 6.09961 11.5667 6.09961C11.7555 6.09961 11.9111 6.16072 12.0333 6.28294C12.1555 6.40516 12.2167 6.56072 12.2167 6.74961C12.2167 6.9385 12.1555 7.09405 12.0333 7.21628L8.96666 10.2829C8.89999 10.3496 8.82777 10.3968 8.74999 10.4246C8.67221 10.4524 8.58888 10.4663 8.49999 10.4663Z"
                        fill="#0D4D80"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              <div className="first-goal">
                <div className="container-first">
                  <select
                    name="option3"
                    value={selectedOptionC}
                    onChange={handleSelectChangeC}
                  >
                    <option value="" disabled>
                      Select an option
                    </option>
                    {formData.map((data, index) => (
                      <React.Fragment key={index}>
                        {data?.satisfaction?.goal1 && (
                          <option value={data?.satisfaction?.goal1}>
                            {data?.satisfaction?.goal1}
                          </option>
                        )}
                        {data?.satisfaction?.goal2 && (
                          <option value={data?.satisfaction?.goal2}>
                            {data?.satisfaction?.goal2}
                          </option>
                        )}
                        {data?.stretch?.goal1 && (
                          <option value={data?.stretch?.goal1}>
                            {data?.stretch?.goal1}
                          </option>
                        )}
                        {data?.stretch?.goal2 && (
                          <option value={data?.stretch?.goal2}>
                            {data?.stretch?.goal2}
                          </option>
                        )}
                      </React.Fragment>
                    ))}
                  </select>

                  <div className="selected-option" id="selectedOptionb">
                    {selectedOptionC ? selectedOptionC : " Select Third Goal"}

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      className="svg"
                    >
                      <path
                        d="M8.49999 10.4663C8.4111 10.4663 8.32777 10.4524 8.24999 10.4246C8.17221 10.3968 8.09999 10.3496 8.03333 10.2829L4.96666 7.21628C4.84444 7.09405 4.78333 6.9385 4.78333 6.74961C4.78333 6.56072 4.84444 6.40516 4.96666 6.28294C5.08888 6.16072 5.24444 6.09961 5.43333 6.09961C5.62221 6.09961 5.77777 6.16072 5.89999 6.28294L8.49999 8.88294L11.1 6.28294C11.2222 6.16072 11.3778 6.09961 11.5667 6.09961C11.7555 6.09961 11.9111 6.16072 12.0333 6.28294C12.1555 6.40516 12.2167 6.56072 12.2167 6.74961C12.2167 6.9385 12.1555 7.09405 12.0333 7.21628L8.96666 10.2829C8.89999 10.3496 8.82777 10.3968 8.74999 10.4246C8.67221 10.4524 8.58888 10.4663 8.49999 10.4663Z"
                        fill="#0D4D80"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              <div className="first-goals">
                <div className="save-btn"></div>

                <div className="save-btn">
                  <button onClick={handle}>Save Changes</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestGoal;
