import React, { useState, useEffect, useRef, useContext } from "react";

import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import "../style/dashboard/dashboard.css";
// import Backimg from "../../assets/images/backimg.png";

import Navbar from "../components/shared/navbar";
import Footer from "../components/shared/footer";
import Genericswot from "../components/questionnaireComp/genericswot";
import Congratsswot from "../components/questionnaireComp/congratsswot";
import axios from "axios";
import arrow from "../../src/assets/images/breadcrumbsArrow.png";
import { visitedPath } from "../components/shared/visitedPath.js";
import { PlanContext } from "../app/context.js";

const Questionnaire = () => {
  const location = useLocation();

  const apiUrl = process.env.REACT_APP_BACKEND_API;
  // const apiUrl = "http://localhost:3333";
  const navigate = useNavigate();
  // const user = useSelector((state) => state.user);
  const { plan } = useContext(PlanContext);
  useEffect(() => {
    visitedPath(plan?._id, { title: "SWOT", path: "/swot" });
  }, [location.pathname]);

  // const plan = useSelector((state) => state.plan.plan);
  const [planData, setPlanData] = useState("");
  const [data, setData] = useState([]);
  const goals = location.state?.goals;
  const [selectedCards, setSelectedCards] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [inputValues, setInputValues] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isVisible, setIsVisible] = useState(true);
  const [activeTab, setActiveTab] = useState("tab1");
  const [mobileWidth, setMobileWidth] = useState(0);
  const tabRefs = useRef([]);
  const [activeTabinDb, setActiveTabInDb] = useState("");

  // console.log(plan);

  useEffect(() => {
    if (plan) {
      setPlanData(plan);
      fetchData(plan);
      fetchInputData(plan);
    }
    // console.log(data);
  }, [plan]);
  // console.log(planData);

  const fetchData = async (planData) => {
    // console.log(planData);
    // console.log(planData._id);
    setIsLoading(true);

    const response = await fetch(`${apiUrl}/answer/getAnswer/${planData._id}`);
    const res = await response.json();
    setData(res);

    setIsLoading(false);

    // setSelectedCards(res)
  };
  const fetchInputData = async (planData) => {
    try {
      const resp = await axios.get(`${apiUrl}/input/getValues/${planData._id}`);

      setInputValues(resp.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  // const test = data.filter((item) => item?.section === "The Long View");
  // console.log("the data is:", test);

  function filterDataByCategory(dataArray, targetCategory) {
    // console.log(targetCategory);

    // { console.log(dataArray?.filter((item) => item?.section === targetCategory), "-------------------") }
    if (
      dataArray.length == 0 ||
      dataArray?.message == "No Answers found" ||
      targetCategory == ""
    )
      return;
    return dataArray?.filter((item) => item?.section === targetCategory);
  }

  const longView = filterDataByCategory(data, "The Long View");
  const WhatWeStandFor = filterDataByCategory(data, "What We Stand For");
  const ProductAndService = filterDataByCategory(data, "Product and Service");
  const Promotion = filterDataByCategory(data, "Promotion");
  const Processes = filterDataByCategory(data, "Processes");
  const People = filterDataByCategory(data, "People");
  const Planning = filterDataByCategory(data, "Planning");
  // console.log(Planning);

  const OtherConsiderations = filterDataByCategory(
    data,
    "Other Considerations"
  );
  const filterTooggle = (dataArray, targetCategory) => {
    if (
      dataArray.length == 0 ||
      dataArray?.message == "No Answers found" ||
      targetCategory == ""
    )
      return;
    const item = dataArray?.find((item) => item?.key === "shows_in_swot");
    if (item && item?.answer === "yes") {
      return dataArray?.filter((item) => item?.category === targetCategory);
    } else {
      if (item === undefined) {
        return [];
      } else {
        return [item];
      }
    }
  };
  const PersonalGrowth = filterTooggle(data, "Personal Growth");

  //console.log("the tab is:", activeTab);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setSearchParams({ activeTab: tab });
  };

  const activateActiveTab = async () => {
    try {
      const resp = await axios.get(
        // `http://localhost:3333/plan/getSinglePlan/${plan._id}`
        `${apiUrl}/plan/getSinglePlan/${plan._id}`
      );
      console.log(resp.data);
      const params = searchParams.get("activeTab");
      if (!params) {
        setSearchParams({ activeTab: resp?.data?.plan?.swotActiveTab });
        setActiveTab(resp?.data?.plan?.swotActiveTab);
      } else {
        setActiveTab(params);
      }
      setActiveTabInDb(resp?.data?.plan?.swotActiveTab);
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    const params = searchParams.get("activeTab");
    if (params) {
      setActiveTab(params);
    }
  }, [searchParams, setSearchParams]);
  useEffect(() => {
    activateActiveTab();
  }, []);

  const handleDrawer = () => {
    setIsVisible(!isVisible);
  };
  useEffect(() => {
    const handleResize = () => {
      setMobileWidth(window.innerWidth);
    };

    handleResize(); // Set initial mobileWidth value
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    const activeTabRef = tabRefs.current.find((ref) => ref.id === activeTab);
    if (activeTabRef) {
      activeTabRef.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  }, [activeTab]);

  useEffect(() => {
    setIsVisible(mobileWidth && mobileWidth >= 1200);
  }, [mobileWidth]);

  // console.log(selectedCards);

  const getQuestionareData = async () => {
    try {
      const response = await axios.get(`${apiUrl}/questionnaire/${plan._id}`);
      // console.log(response.data);
      const data = response?.data?.questionaires?.selected;

      if (data !== undefined) {
        setSelectedCards(data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getQuestionareData();
  }, []);

  // console.log(selectedCards.length);

  let currentLink = [];
  const crumbs = location.pathname
    .split("/")
    .filter((crumb) => crumb !== "")
    .map((crumb) => {
      currentLink.push(crumb);
      return (
        <div className="crumb" key={crumb}>
          <p>{crumb}</p>
        </div>
      );
    });

  // console.log(activeTabinDb);
  return (
    <>
      <Navbar />
      <section className="dashboard-question container-fluids container-space ">
        <div className="menuDiv">
          <button onClick={handleDrawer}>
            {/* <Icon
              className="menuIcon"
              icon="mingcute:menu-fill"
              color="black"
              width="20"
              height="20"
            /> */}
          </button>
        </div>
        <div className="general-content">
          <div
            className="main-breadcrumps px-3"
            style={{ display: "flex", cursor: "pointer", alignItems: "center" }}
          >
            <p
              className="breadCrumbs"
              style={{ display: "flex" }}
              onClick={() => navigate("/plans")}
            >
              Plans
              <img className="arrowCrumbs" src={arrow} alt="" />
            </p>
            <p
              className="breadCrumbs"
              style={{ display: "flex" }}
              onClick={() => navigate("/questionnaire ")}
            >
              Questionnaire
              <img className="arrowCrumbs" src={arrow} alt="" />
            </p>
            <p
              className="breadCrumbs"
              style={{ display: "flex" }}
              onClick={() => navigate("/goal ")}
            >
              Goals
              <img className="arrowCrumbs" src={arrow} alt="" />
            </p>
            <p className="breadCrumbs">{crumbs}</p>
          </div>
          <h6>
            Update SWOT Review <br /> <span>{planData?.planName}</span>
          </h6>
          <h6 className="mt-2 mb-2 text-center ">
            (You are viewing {plan?.planUser}'s Plan)
          </h6>
        </div>
        <div className={isVisible ? "left trans-0 " : "left trans-0"}>
          <ul className="sidebarPAdding">
            {[
              "tab1",
              "tab2",
              "tab3",
              "tab4",
              "tab5",
              "tab6",
              "tab7",
              "tab8",
              "tab9",
              "tab10",
            ].map((tab, index) => {
              const tabNumber = parseInt(tab.replace("tab", ""), 10);
              const activeTabNumber = parseInt(
                activeTabinDb.replace("tab", ""),
                10
              );
              const isDisabled = tabNumber > activeTabNumber;

              return (
                <li
                  key={tab}
                  id={tab}
                  ref={(el) => (tabRefs.current[index] = el)}
                  className={`tab subHeading ${
                    activeTab === tab ? "activetab" : ""
                  } ${isDisabled ? "disabled" : ""}`}
                  onClick={() => !isDisabled && handleTabChange(tab)}
                >
                  {tab === "tab1" && "The Long View"}
                  {tab === "tab2" && "What we stand for"}
                  {tab === "tab3" && "Product & Services"}
                  {tab === "tab4" && "Promotion"}
                  {tab === "tab5" && "Processes"}
                  {tab === "tab6" && "People"}
                  {tab === "tab7" && "Planning"}
                  {tab === "tab8" && "Personal Growth"}
                  {tab === "tab9" && "Other Consideration"}
                  {tab === "tab10" && "Completed"}
                </li>
              );
            })}
          </ul>
        </div>
        <div className="goalsHeading">
          {/* {console.log(goals)} */}

          {goals?.map((goal, index) => (
            <div className="goalDiv">
              <h5 key={index} style={{ color: "#ffff" }}>
                {goal}
              </h5>
            </div>
          ))}
        </div>
        <div className="right">
          {/* <div className="rightBgimg">
            <img src={Backimg} alt="back" className="backimg" />
          </div> */}

          {/* <div className="rightHeading">
            <h5>
              Business Questionnaire <span>filled by</span> Andrew
            </h5>
          </div> */}

          {activeTab === "tab1" && (
            <Genericswot
              selectedCards={selectedCards}
              setSelectedCards={setSelectedCards}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              data={["Purpose", "Vision", "Mission"]}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              category="Purpose | Why we do what we do "
              title="The Long View"
              answers={longView}
              plan={plan}
              inputValues={inputValues}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              setActiveTabInDb={setActiveTabInDb}
              activeTabinDb={activeTabinDb}
            />
          )}
          {activeTab === "tab2" && (
            <Genericswot
              selectedCards={selectedCards}
              setSelectedCards={setSelectedCards}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              data={[" USP | Our Unique Selling Proposition", "Values"]}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              category="Purpose | Why we do what we do "
              title="What We Stand For"
              answers={WhatWeStandFor}
              plan={plan}
              inputValues={inputValues}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              setActiveTabInDb={setActiveTabInDb}
              activeTabinDb={activeTabinDb}
            />
          )}
          {activeTab === "tab3" && (
            <Genericswot
              selectedCards={selectedCards}
              setSelectedCards={setSelectedCards}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              data={[
                " Product and Services",
                "For Those Selling Physical Goods (Only) ",
              ]}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              category="Purpose | Why we do what we do "
              title="Product and Services"
              answers={ProductAndService}
              plan={plan}
              inputValues={inputValues}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              setActiveTabInDb={setActiveTabInDb}
              activeTabinDb={activeTabinDb}
            />
          )}
          {activeTab === "tab4" && (
            <Genericswot
              selectedCards={selectedCards}
              setSelectedCards={setSelectedCards}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              data={["Marketing", "Sales "]}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              category="Purpose | Why we do what we do "
              title="Promotion"
              answers={Promotion}
              plan={plan}
              inputValues={inputValues}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              setActiveTabInDb={setActiveTabInDb}
              activeTabinDb={activeTabinDb}
            />
          )}
          {activeTab === "tab5" && (
            <Genericswot
              selectedCards={selectedCards}
              setSelectedCards={setSelectedCards}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              data={[
                "Customer intake | Follow The Customer",
                "Production",
                "Delivery",
                "post-mortem",
                "Invoicing & Billing",
                "internal | Physical Facilities",
                "inventory management",
                "Financial Management",
                "Key Performance Indicators & other considerations",
              ]}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              category="Purpose | Why we do what we do "
              title="Processes"
              answers={Processes}
              plan={plan}
              inputValues={inputValues}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              setActiveTabInDb={setActiveTabInDb}
              activeTabinDb={activeTabinDb}
            />
          )}
          {activeTab === "tab6" && (
            <Genericswot
              selectedCards={selectedCards}
              setSelectedCards={setSelectedCards}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              data={[
                "Current Situation",
                "recruitment ",
                "induction",
                "training",
                "compensation",
                "performance management",
                "Subcontractor & other considerations",
              ]}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              category="Purpose | Why we do what we do "
              title="People"
              answers={People}
              plan={plan}
              inputValues={inputValues}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              setActiveTabInDb={setActiveTabInDb}
              activeTabinDb={activeTabinDb}
            />
          )}
          {activeTab === "tab7" && (
            <Genericswot
              selectedCards={selectedCards}
              setSelectedCards={setSelectedCards}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              data={["Planning"]}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              category="Purpose | Why we do what we do "
              title="Planning"
              answers={Planning}
              plan={plan}
              inputValues={inputValues}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              setActiveTabInDb={setActiveTabInDb}
              activeTabinDb={activeTabinDb}
            />
          )}
          {activeTab === "tab8" && (
            <Genericswot
              selectedCards={selectedCards}
              setSelectedCards={setSelectedCards}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              data={["Personal Growth"]}
              category="Purpose | Why we do what we do "
              title="Personal Growth"
              answers={PersonalGrowth}
              plan={plan}
              inputValues={inputValues}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              setActiveTabInDb={setActiveTabInDb}
              activeTabinDb={activeTabinDb}
            />
          )}
          {activeTab === "tab9" && (
            <Genericswot
              selectedCards={selectedCards}
              setSelectedCards={setSelectedCards}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              data={["Impact Assessment", "Pestle Analysis", "the final five"]}
              category="Purpose | Why we do what we do "
              title="Other Consideration"
              answers={OtherConsiderations}
              plan={plan}
              inputValues={inputValues}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              setActiveTabInDb={setActiveTabInDb}
              activeTabinDb={activeTabinDb}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
            />
          )}
          {/* {activeTab === "tab10" && (
            <Genericswot
              selectedCards={selectedCards}
              setSelectedCards={setSelectedCards}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              data={[]}
              category="Purpose | Why we do what we do "
              title="Your Added Questions"
              answers={OtherConsiderations}
              plan={plan}
              inputValues={inputValues}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
            />
          )} */}

          {activeTab === "tab10" && (
            <Congratsswot
              // data={["additional Thread & opportunities", "the final five"]}
              selectedCards={selectedCards}
              category="Purpose | Why we do what we do "
              title="Other Consideration"
            />
          )}
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Questionnaire;
