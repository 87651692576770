import React, { useContext, useEffect, useState } from "react";
import "../../style/Admindashboard/Profile.css";
import Footer from "../shared/footer";
import "../../style/Admindashboard/NavbarNew.css";
import NavbarNew from "./NavbarNew";
import { useSelector } from "react-redux";
import Navbar from "../shared/navbar";
import { toast } from "react-toastify";
import { AuthContext } from "../../app/context";

const Profile = () => {
  const apiUrl = process.env.REACT_APP_BACKEND_API;

  const [userName, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [companyName, setCompanyName] = useState("");
  const { user } = useContext(AuthContext);

  useEffect(() => {
    fetch(`${apiUrl}/user/${user.userId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log(data);
        setUsername(data.userName);
        setEmail(data.email);
        setPhone(data.contactNo);
        setCountry(data.country);
        setCompanyName(data.companyName);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const updateProfile = async () => {
    const res = await fetch(`${apiUrl}/user/update/${user.userId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userName: userName,
        email: email,
        contactNo: phone,
        country: country,
        companyName: companyName,
      }),
    });
    const data = await res.json();

    toast.success(data.message);
  };

  const formSubmit = (e) => {
    e.preventDefault();

    updateProfile();
  };
  
  return (
    <>
      {/* <NavbarNew /> */}
      <Navbar />
      <div className="container-fluid ">
        <div className="row ">
          <div className="col-lg-12 col-md-12 col-sm-12 first">
            <div className="admindashboard d-flex align-items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
              >
                <g clip-path="url(#clip0_2183_8702)">
                  <path
                    d="M9.375 12.5C5.92813 12.5 3.125 9.69687 3.125 6.25C3.125 2.80312 5.92813 0 9.375 0C12.8219 0 15.625 2.80312 15.625 6.25C15.625 9.69687 12.8219 12.5 9.375 12.5ZM13.8229 20.2917C12.976 21.1385 12.5 22.2865 12.5 23.4844V25H14.0156C15.2135 25 16.3615 24.524 17.2083 23.6771L24.299 16.5865C25.2333 15.6521 25.2333 14.1354 24.299 13.201C23.3646 12.2667 21.8479 12.2667 20.9135 13.201L13.8229 20.2917ZM10.4167 23.4844C10.4167 21.7219 11.1031 20.0646 12.349 18.8187L14.824 16.3437C13.2865 15.2396 11.4083 14.5823 9.375 14.5823C4.20625 14.5833 0 18.7896 0 23.9583C0 24.5333 0.466667 25 1.04167 25H10.4167V23.4844Z"
                    fill="#0D4D80"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2183_8702">
                    <rect width="25" height="25" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span>Edit Profile</span>
            </div>
          </div>
          <div className="col-lg-12 d-flex  justify-content-center my-5">
            <div className="form_edit d-flex justify-content-center">
              <div className="edit d-flex justify-content-center">
                {/* <h2>Let’s bring coach on board</h2> */}
                <div className="mt-3 ">
                  <div className="text-center d-flex">
                    {user?.picture ? (
                      <img
                        src={user.picture}
                        width={50}
                        height={50}
                        style={{ borderRadius: "50%" }}
                      />
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="50"
                        height="51"
                        viewBox="0 0 50 51"
                        fill="none"
                      >
                        <path
                          d="M25 50.1613C38.8071 50.1613 50 38.9685 50 25.1615C50 11.3544 38.8071 0.161591 25 0.161591C11.1929 0.161591 0 11.3544 0 25.1615C0 38.9685 11.1929 50.1613 25 50.1613Z"
                          fill="#58B0E0"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M18.8145 31.456L31.6842 31.5539V42.0053L18.8145 41.9074V31.456Z"
                          fill="#F9DCA4"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M35.5751 47.8191C32.3038 49.3427 28.7419 50.1416 25.1332 50.1611C21.5245 50.1807 17.9542 49.4204 14.6665 47.9324V39.3337H35.5743L35.5751 47.8191Z"
                          fill="#E6E6E6"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M27.0251 50.0807C26.3573 50.1341 25.6823 50.161 25 50.1616C24.4849 50.1616 23.9738 50.1462 23.4668 50.1155L24.3001 42.8436H26.25L27.0251 50.0807Z"
                          fill="#E9573E"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M20.7592 49.7829C18.0027 49.2695 15.3408 48.3386 12.8651 47.0224C10.4872 45.6992 8.34031 43.9982 6.5083 41.986L7.54714 39.4779L18.8134 34.452C18.8134 38.6688 20.1977 45.3589 20.7592 49.7829Z"
                          fill="#677079"
                        />
                        <path
                          opacity="0.11"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M31.687 31.52V34.134C27.8585 38.6057 20.5438 37.973 18.9038 31.3735L31.687 31.52Z"
                          fill="black"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M25.1851 6.98338C42.4028 6.98338 35.6924 35.5157 25.1851 35.5157C15.2603 35.5157 7.9674 6.98338 25.1851 6.98338Z"
                          fill="#FFE8BE"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M14.8584 19.6162C12.3745 20.6995 14.4862 25.8346 16.0493 25.812C15.4009 23.8028 15.001 21.7226 14.8584 19.6162Z"
                          fill="#F9DCA4"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M35.7121 19.6154C38.1959 20.6987 36.0843 25.8346 34.522 25.8112C35.1698 23.8022 35.5695 21.7214 35.7121 19.6154Z"
                          fill="#F9DCA4"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M17.7429 11.7538C15.0455 14.5515 15.9945 22.2958 15.9945 22.2958L15.145 21.8953C9.84237 1.35009 40.7389 1.34928 35.4064 21.8953L34.5569 22.2958C34.5569 22.2958 35.5067 14.5515 32.8093 11.7538C29.4767 14.986 21.0568 14.969 17.7429 11.7538Z"
                          fill="#464449"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M29.7466 49.7109C35.2137 48.6551 40.1711 45.8022 43.8308 41.6058L42.9489 39.4771L31.6835 34.4512C31.6835 38.6681 30.3081 45.287 29.7466 49.7109Z"
                          fill="#677079"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M31.6846 34.1259L25.272 39.3565L30.59 44.1405C30.59 44.1405 32.0074 36.2893 31.6846 34.1259Z"
                          fill="white"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M18.8133 34.1259L25.2769 39.3565L19.9007 44.1405C19.9007 44.1405 18.488 36.2893 18.8133 34.1259Z"
                          fill="white"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M22.7417 41.6073L25.2797 39.3274L27.8178 41.6073L25.2797 43.8864L22.7417 41.6073Z"
                          fill="#E9573E"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M20.8469 49.8159C19.8138 49.6431 18.7927 49.4053 17.7895 49.104C17.7581 49.0797 17.7273 49.0546 17.6973 49.0287L14.8372 43.8588L18.5112 41.2213H14.6665L15.4893 35.6784L18.8146 33.5757C18.9586 39.5384 20.2377 46.2423 20.8469 49.8159Z"
                          fill="#434955"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M29.6475 49.7294C30.1232 49.6501 30.5941 49.54 31.0609 49.4187C31.6127 49.2755 32.1612 49.1137 32.7065 48.9502L32.8149 48.9154L35.6556 43.8062L31.9816 41.1833H35.8263L35.0035 35.6679L31.6782 33.5757C31.5342 39.5077 30.2559 46.1735 29.6475 49.7294Z"
                          fill="#434955"
                        />
                      </svg>
                    )}

                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                      >
                        <circle
                          cx="10.6426"
                          cy="10.6426"
                          r="9.64258"
                          fill="#F5F5F5"
                          stroke="#FCFCFC"
                          stroke-width="2"
                        />
                      </svg>
                    </span>
                    <p>Profile & Contact Info</p>
                  </div>
                </div>
                <div className="container contact_div">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 ">
                      <form action="" onSubmit={formSubmit}>
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          ></label>
                          <input
                            type="userName"
                            className="form-control"
                            id="exampleFormControlInput1"
                            name="userName"
                            value={userName}
                            onChange={(e) => setUsername(e.target.value)}
                            placeholder="User Name"
                          />
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          ></label>
                          <input
                            type="email"
                            className="form-control"
                            id="exampleFormControlInput1"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="E-mail"
                            disabled
                          />
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          ></label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                            name="companyName"
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                            placeholder="CompanyName"
                          />
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          ></label>
                          <input
                            type="phone"
                            className="form-control"
                            id="exampleFormControlInput1"
                            name="phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            placeholder="Phone No"
                          />
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          ></label>
                          <input
                            type="country"
                            className="form-control"
                            id="exampleFormControlInput1"
                            name="country"
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                            placeholder="Country"
                          />
                        </div>
                        <div className="text-center">
                          {" "}
                          <button className="btn btn-outline-primary">
                            Save Profile Info
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Profile;
