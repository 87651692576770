import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import AdminDashboardLayout from "./AdminDashboardLayout";
import { Form, Table } from "react-bootstrap";
import { AuthContext, PlanContext } from "../../../app/context";
import { useNavigate, useParams } from "react-router-dom";

const AdminDashboardUserPlansTable = () => {
  const apiUrl = process.env.REACT_APP_BACKEND_API;
  // const apiUrl = "http://localhost:3333";
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useContext(AuthContext);
  const { setPlanData } = useContext(PlanContext);
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const resp = await axios.get(`${apiUrl}/admin-dashboard/plan/${id}`, {
          headers: {
            Authorization: `Bearer ${user.token}`, //auth token for admin
          },
        });
        setPlans(resp.data.plans);
        setLoading(false);
      } catch (error) {
        if (error.response.data?.message === "Invalid Token") {
          navigate("/signin");
        }
        console.log(error.response.data);
        setLoading(false);
      }
    };
    fetchUsers();
  }, []);
  const handlePlanClick = (plan) => {
    setPlanData(plan);
    navigate(plan?.lastvisitedPath);
  };
  const handleProgressClick = (plan, path) => {
    setPlanData(plan);
    navigate(path);
  };
  return (
    <AdminDashboardLayout>
      <>
        <h4 className="text-center p-2">Plans Lists</h4>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Plan Id</th>
              <th>Plan Name</th>
              <th>Plan Owner</th>
              <th>Progress</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <h5 className="text-center my-4 ">Fetching Plans....</h5>
            )}
            {!loading && plans?.length === 0 ? (
              <h5 className="text-center my-4 ">No Plan Created</h5>
            ) : (
              plans?.map((plan, index) => {
                return (
                  <tr key={plan._id}>
                    <td>{plan._id}</td>
                    <td>{plan.planName}</td>
                    <td>{plan.planUser}</td>
                    <td>
                      <Form.Select
                        aria-label="select progess"
                        className="border-0 bg-transparent"
                        onChange={(e) =>
                          handleProgressClick(plan, e.target.value)
                        }
                      >
                        <option>select progress</option>
                        {plan?.visitedPath?.map((path, index) => (
                          <option key={index} value={path?.path}>
                            {path?.title}
                          </option>
                        ))}
                      </Form.Select>
                    </td>
                    <td
                      className="text-blue pointer"
                      onClick={() => handlePlanClick(plan)}
                    >
                      view plan
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </Table>
        <button
          className="btn btn-primary"
          onClick={() => navigate("/dashboard/admin")}
        >
          Back
        </button>
      </>
    </AdminDashboardLayout>
  );
};

export default AdminDashboardUserPlansTable;
