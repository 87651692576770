import React, { useContext, useEffect, useState } from "react";
import Navbar from "../components/shared/navbar";
import Footer from "../components/shared/footer";
import Threemonthplan from "../components/ninetydaysplan/threemonthplan";
import Chart from "./gantt";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { getData } from "./Data";
import Gantt2 from "./Gantt2";
import { Icon } from "@iconify/react";
import GanttMain from "../gantt/example/App";
import arrow from "../../src/assets/images/breadcrumbsArrow.png";
import { visitedPath } from "../components/shared/visitedPath";
import { AuthContext, PlanContext } from "../app/context";
const Ninetydplan = () => {
  const apiUrl = process.env.REACT_APP_BACKEND_API;
  const navigate = useNavigate();
  const location = useLocation();
  const strategicData = location.state?.buttonMessages;
  const StrategicFocus = location?.state?.userFocus;
  const userPriorities = location?.state?.userPriorities;
  const [userGoals, setUserGoals] = useState();
  const [finalThemeData, setFinalThemeData] = useState([]);
  const { plan } = useContext(PlanContext);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    visitedPath(plan?._id, {
      title: "Ninety Day Plan",
      path: "/ninetydayplan",
    });
  }, [location.pathname]);

  const dataArray = [strategicData, userGoals, userPriorities];
  const serializedData = encodeURIComponent(JSON.stringify(dataArray));
  let currentLink = [];
  const crumbs = location.pathname
    .split("/")
    .filter((crumb) => crumb !== "")
    .map((crumb) => {
      currentLink.push(crumb);
      return (
        <div className="crumb" key={crumb}>
          <p>{crumb}</p>
        </div>
      );
    });

  useEffect(() => {
    const getGoals = async () => {
      try {
        const response = await fetch(`${apiUrl}/goal/${plan._id}`);

        if (!response.ok) {
          console.log("Network response was not ok");
        }
        const json = await response.json();
        setUserGoals(json[0]?.selectedGoals);
      } catch (error) {
        console.log(error.message);
      }
    };
    getGoals();
  }, []);

  const getFinalData = async () => {
    try {
      const response = await fetch(`${apiUrl}/finalTheme/${plan._id}`);
      if (!response.ok) {
        console.log("Network response was not ok");
      }
      let json = await response.json();
      // console.log(json)
      setFinalThemeData(json);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getFinalData();
  }, []);

  return (
    <>
      <Navbar />
      <div className="ninetydplan">
        <div className="ninetyplanContent">
          <div className="ninetyplan-title">
            <div
              className="main-breadcrumps px-4"
              style={{
                display: "flex",
                cursor: "pointer",
                alignItems: "center",
                gap: "5px",
              }}
            >
              {/* <a style={{ display: 'flex' }} onClick={() => navigate('/dashboardland ')}>
                Dashboard
                <Icon
                  icon="fa6-solid:greater-than"
                  color="#0d4d80"
                  width="18"
                  height="18"

                  className="icon"

                  style={{
                    marginLeft: "5px",
                    marginRight: "5px"
                  }}
                />

              </a> */}
              <p
                className="breadCrumbs"
                style={{ display: "flex" }}
                onClick={() => navigate("/plan")}
              >
                Plans
                <img className="arrowCrumbs" src={arrow} alt="" />
              </p>
              <p
                className="breadCrumbs"
                style={{ display: "flex" }}
                onClick={() => navigate("/questionnaire ")}
              >
                Questionnaire
                <img className="arrowCrumbs" src={arrow} alt="" />
              </p>
              <p
                className="breadCrumbs"
                style={{ display: "flex" }}
                onClick={() => navigate("/goal ")}
              >
                Goals
                <img className="arrowCrumbs" src={arrow} alt="" />
              </p>
              <p
                className="breadCrumbs"
                style={{ display: "flex" }}
                onClick={() => navigate("/swot ")}
              >
                Swot
                <img className="arrowCrumbs" src={arrow} alt="" />
              </p>
              <p
                className="breadCrumbs"
                style={{ display: "flex" }}
                onClick={() => navigate("/medical ")}
              >
                Theme
                <img className="arrowCrumbs" src={arrow} alt="" />
              </p>
              <p
                className="breadCrumbs"
                style={{ display: "flex" }}
                onClick={() => navigate("/finaltheme ")}
              >
                FinalTheme
                <img className="arrowCrumbs" src={arrow} alt="" />
              </p>
              <p
                className="breadCrumbs"
                style={{ display: "flex" }}
                onClick={() => navigate("/tacticalplan ")}
              >
                TacticalPlan
                <img className="arrowCrumbs" src={arrow} alt="" />
              </p>

              <p className="breadCrumbs">90DaysPlan</p>
              {/* <a style={{ display: 'flex' }} onClick={() => navigate('/dashboardland')}>
            DashboardLand
            <Icon
              icon="fa6-solid:greater-than"
              color="#0d4d80"
              width="18"
              height="18"

              className="icon"

              style={{
                marginLeft: "5px",
                marginRight: "5px"
              }}
            />
            {crumbs}
          </a> */}
            </div>
            <h6>90-Day Plan</h6>
            <span>{user?.companyName}</span>
            <h4 className="mt-2 mb-2 text-center ">
              (You are viewing {plan?.planUser}'s Plan)
            </h4>
          </div>
          <div className="planscard">
            <div className="leftCard">
              <div className="goalHeader">
                <span>Major Goals</span>
              </div>

              {userGoals?.map((goals) => {
                return (
                  <div key={goals} className="goalFooter">
                    <span> {goals} </span>
                  </div>
                );
              })}
            </div>

            <div className="rightCard">
              <div className="goalHeader">
                <span>Strategic Focus</span>
              </div>
              {/* {StrategicFocus?.map((stFocus) => {
                return ( */}
              <div className="sale-section">
                <span>
                  {" "}
                  {finalThemeData?.themes
                    ? finalThemeData?.themes[1000]
                    : StrategicFocus
                    ? StrategicFocus[0]
                    : ""}
                </span>
              </div>
              <div className="sale-section">
                <span> {StrategicFocus ? StrategicFocus[1] : ""}</span>
              </div>
              {/* );
              })} */}
            </div>
          </div>

          {/* <div className="strategicpriorities p-0">
            <div className="prioritiesContent">
              <div className="prioritiesHeader">
                <span>Strategic Priorities</span>
              </div>
            </div>
          </div> */}
          <h1
            className="d-flex justify-content-center mt-5 mb-5"
            style={{ fontWeight: "600", color: "#0D4D80" }}
          >
            Gantt Chart
          </h1>

          <GanttMain />

          {/* <Threemonthplan /> */}

          {/* <div className="">
            {userPriorities?.map((priority, priorityIndex) => {
              return (
                <div className="sale-section">
                  <div className="strategicpriorities p-0">
                    <div className="prioritiesContent">
                      <div className="prioritiesHeader">
                        <span> {priority} </span>
                      </div>
                    </div>
                  </div>

                  {userGoals?.map((goals, goalsIndex) => {
                    if (goals == "") return;

                    if (!strategicData["Input" + priorityIndex + goalsIndex])
                      return;

                    const dataArray = [
                      strategicData["Input" + priorityIndex + goalsIndex],
                      userGoals,
                      userPriorities,
                    ];

                    const serializedData = encodeURIComponent(
                      JSON.stringify(dataArray)
                    );

                    return (
                      <div className="sale-section">
                        <div className="strategicpriorities p-0 w-25">
                          <div className="prioritiesContent">
                            <div className="prioritiesHeader">
                              <span> {goals} </span>
                            </div>
                          </div>
                        </div>

                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div> */}
          {/* <Gantt2
                  stepsData={strategicData["Input" + priorityIndex + goalsIndex]}
                  userGoals={userGoals}
                  userPriorities={userPriorities}

                /> */}
          {/* <iframe
                  src={`/chart?data=${serializedData}`}
                  style={{ border: '2px solid lime', width: '100%', height: '350px' }}
                  title="Gantt Chart 2"
                ></iframe> */}

          <div className="planButton-next">
            {/* {console.log())} */}

            {user.role === "user" || user.role === "coach" ? (
              <button
                onClick={() => {
                  navigate("/onepageplan", {
                    state: {
                      strategicData,
                      userGoals,
                      StrategicFocus,
                      userPriorities,
                    },
                  });
                }}
              >
                Next plan
              </button>
            ) : (
              JSON.parse(localStorage.getItem("Member"))?.permissions?.includes(
                "One Page Plan"
              ) && (
                <button
                  onClick={() => {
                    navigate("/onepageplan", {
                      state: {
                        strategicData,
                        userGoals,
                        StrategicFocus,
                        userPriorities,
                      },
                    });
                  }}
                >
                  1 Page Plan
                </button>
              )
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Ninetydplan;
