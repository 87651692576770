import React, { useContext, useEffect, useState } from "react";
import Footer from "../components/shared/footer";
import Navbar from "../components/shared/navbar";
import Strategicpriorities from "../components/statuscomp/strategicpriorities";
import Kanban from "./kanban";
import { useLocation, useNavigate } from "react-router-dom";
import { Icon } from "@mui/material";
import arrow from "../../src/assets/images/breadcrumbsArrow.png";
import { visitedPath } from "../components/shared/visitedPath";
import { PlanContext } from "../app/context";

const Statuspage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const strategicData = location.state?.buttonMessages;
  const userGoals = location?.state?.userGoals;
  const StrategicFocus = location?.state?.userFocus;
  const userPriorities = location?.state?.userPriorities;
  const apiUrl = process.env.REACT_APP_BACKEND_API;
  const [finalThemeData, setFinalThemeData] = useState([]);
  const { plan } = useContext(PlanContext);

  useEffect(() => {
    visitedPath(plan?._id, { title: "Kanban Board", path: "/statuspage" });
  }, [location.pathname]);

  const getFinalData = async () => {
    try {
      const response = await fetch(`${apiUrl}/finalTheme/${plan._id}`);

      if (!response.ok) {
        console.log("Network response was not ok");
      }
      let json = await response.json();
      console.log(json);
      setFinalThemeData(json);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getFinalData();
  }, []);
  // console.log(strategicData, " strategicData =======================");
  // console.log(userGoals, " userGoals =======================");
  // console.log(StrategicFocus, " StrategicFocus =======================");
  // console.log(userPriorities, " userPriorities =======================");

  return (
    <>
      {" "}
      <Navbar />
      <div className="ninetydplan">
        <div className="ninetyplanContent">
          <div
            className="main-breadcrumps px-4 py-3"
            style={{ display: "flex", cursor: "pointer", alignItems: "center" }}
          >
            {/* <a style={{ display: 'flex' }} onClick={() => navigate('/dashboardland ')}>
              Dashboard
              <Icon
                icon="fa6-solid:greater-than"
                color="#0d4d80"
                width="18"
                height="18"

                className="icon"

                style={{
                  marginLeft: "5px",
                  marginRight: "5px"
                }}
              />

            </a> */}
            <p
              className="breadCrumbs"
              style={{ display: "flex" }}
              onClick={() => navigate("/plan")}
            >
              Plans
              <img className="arrowCrumbs" src={arrow} alt="" />
            </p>
            <p
              className="breadCrumbs"
              style={{ display: "flex" }}
              onClick={() => navigate("/questionnaire ")}
            >
              Questionnaire
              <img className="arrowCrumbs" src={arrow} alt="" />
            </p>
            <p
              className="breadCrumbs"
              style={{ display: "flex" }}
              onClick={() => navigate("/goal ")}
            >
              Goals
              <img className="arrowCrumbs" src={arrow} alt="" />
            </p>
            <p
              className="breadCrumbs"
              style={{ display: "flex" }}
              onClick={() => navigate("/swot ")}
            >
              Swot
              <img className="arrowCrumbs" src={arrow} alt="" />
            </p>
            <p
              className="breadCrumbs"
              style={{ display: "flex" }}
              onClick={() => navigate("/medical ")}
            >
              Theme
              <img className="arrowCrumbs" src={arrow} alt="" />
            </p>
            <p
              className="breadCrumbs"
              style={{ display: "flex" }}
              onClick={() => navigate("/finaltheme ")}
            >
              FinalTheme
              <img className="arrowCrumbs" src={arrow} alt="" />
            </p>
            <p
              className="breadCrumbs"
              style={{ display: "flex" }}
              onClick={() => navigate("/tacticalplan ")}
            >
              TacticalPlan
              <img className="arrowCrumbs" src={arrow} alt="" />
            </p>

            <p
              className="breadCrumbs"
              onClick={() => navigate("/ninetydayplan")}
            >
              90DaysPlan
            </p>
            {/* <a style={{ display: 'flex' }} onClick={() => navigate('/dashboardland')}>
            DashboardLand
            <Icon
              icon="fa6-solid:greater-than"
              color="#0d4d80"
              width="18"
              height="18"

              className="icon"

              style={{
                marginLeft: "5px",
                marginRight: "5px"
              }}
            />
            {crumbs}
          </a> */}
          </div>
          <div className="ninetyplan-title">
            <h6>90-Days Plan</h6>
            <span>Just up to questions</span>
            <h4 className="mt-2 mb-2 text-center ">
              (You are viewing {plan?.planUser}'s Plan)
            </h4>
          </div>
          <div className="planscard">
            <div className="leftCard">
              <div className="goalHeader">
                <span>Major Goals</span>
              </div>
              <div className="sale-section">
                {userGoals
                  ? userGoals?.map((data) => {
                      return (
                        <div className="middle">
                          <span>{data}</span>
                        </div>
                      );
                    })
                  : null}{" "}
                {/* <span>Get more sales than previous year.</span> */}
              </div>
              {/* <div className="goalFooter">
                <span>To achieve maximum profit.</span>
              </div> */}
            </div>

            <div className="rightCard">
              <div className="goalHeader">
                <span>Strategic Focus</span>
              </div>
              <div className="sale-section">
                {" "}
                {/* {StrategicFocus ? StrategicFocus.map((item, index) => ( */}
                <div className="middle">
                  {/* {console.log(item)} */}
                  <span>
                    {finalThemeData.themes
                      ? finalThemeData.themes[1000]
                      : StrategicFocus
                      ? StrategicFocus[0]
                      : ""}
                  </span>
                </div>
                <div className="middle">
                  {/* {console.log(item)} */}
                  <span>{StrategicFocus ? StrategicFocus[1] : ""}</span>
                </div>
                {/* )) : null} */}
              </div>
              {/* <div className="goalFooter">
                <span>Reduce Variable Cost by Cost Reduction</span>
              </div> */}
            </div>
          </div>
          {/* <Threemonthplan /> */}

          <div>
            <Kanban
              strategicData={strategicData}
              userGoals={userGoals}
              StrategicFocus={StrategicFocus}
              userPriorities={userPriorities}
            />
          </div>

          {/* <Strategicpriorities /> */}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Statuspage;
