import axios from "axios";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function PlanModal({ selectedUser, show, handleClose }) {
  const apiUrl = process.env.REACT_APP_BACKEND_API;
  const navigate = useNavigate();
  const [planName, setPlanName] = useState("");
  const [planNameError, setPlanNameError] = useState("");
  const handlePlanSubmit = async () => {
    if (!planName) {
      setPlanNameError("Plan name is required");
      return;
    }
    if (selectedUser) {
      try {
        const response = await axios.post(`${apiUrl}/plan`, {
          planName: planName,
          planUser: selectedUser?.userName,
          user: selectedUser?._id,
        });
        if (response.data.success) {
          navigate(`/dashboard/admin/plan/${selectedUser?._id}`);
        } else {
          toast.error("something went wrong!");
        }
      } catch (error) {
        toast.error(error?.response?.data?.message);
      }
    } else {
      toast.error("please select user for which you are creating plan");
    }
  };
  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          handleClose();
          setPlanNameError("");
        }}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>userId : {selectedUser?._id}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="name@example.com"
                value={selectedUser?.email}
                disabled
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
              <Form.Label>User name</Form.Label>
              <Form.Control
                type="text"
                value={selectedUser?.userName}
                disabled
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Plan Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter plan name."
                value={planName}
                onChange={(e) => setPlanName(e.target.value)}
                autoFocus
              />
            </Form.Group>
            {planNameError && (
              <span className="text-danger">{planNameError}</span>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              handleClose();
              setPlanNameError("");
            }}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={handlePlanSubmit}>
            Create Plan
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PlanModal;
