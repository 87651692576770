import axios from "axios";
import { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthContext } from "../../../app/context";

function PasswordModal({ selectedUser, show, handleClose }) {
  const apiUrl = process.env.REACT_APP_BACKEND_API;
  //   const apiUrl = "http://localhost:3333";
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const handlePasswordChange = async () => {
    if (!password || !confirmPassword) {
      setPasswordError("password and confirm password is required.");
      return;
    }
    if (password !== confirmPassword) {
      setPasswordError("password and confirm password not match.");
      return;
    }
    if (selectedUser) {
      try {
        const response = await axios.put(
          `${apiUrl}/admin-dashboard/update-password`,
          {
            password: password,
            userId: selectedUser?._id,
          },
          {
            headers: {
              Authorization: `Bearer ${user?.token}`,
            },
          }
        );
        if (response.data.success) {
          handleClose();
        } else {
          toast.error("something went wrong!");
        }
      } catch (error) {
        toast.error(error?.response?.data?.message);
      }
    } else {
      toast.error("please select user for which you are changing password.");
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          handleClose();
          setPasswordError("");
        }}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>userId : {selectedUser?._id}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="name@example.com"
                value={selectedUser?.email}
                disabled
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
              <Form.Label>User name</Form.Label>
              <Form.Control
                type="text"
                value={selectedUser?.userName}
                disabled
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Enter Password</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter password."
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoFocus
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea12"
            >
              <Form.Label>Enter Confirm Password</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter password."
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Form.Group>
            {passwordError && (
              <span className="text-danger">{passwordError}</span>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              handleClose();
              setPasswordError("");
            }}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={handlePasswordChange}>
            Update Password
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PasswordModal;
